import React from "react";

import SiteStructure from "../components/siteStructure/SiteStructure";
import CarouselAR from "../components/Carousel/Carousel";
import SEO from "../components/SEO/Seo";

import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import "./index.scss";

const IndexPage = () => {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location =
  //     "http://www.blueskysa.com.ar/movile/01-empresa-bluesky.html";
  // }
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio."
        keywords="Blue Sky SA, Botellas, Botellas de vidrio, Tapas rosca, Corchos, Tapones, Argentina, Mendoza, Tapas Pilfer, Screw Caps, Aluminio,Argentina, Mendoza"
        description=" Blue Sky es una empresa que se especializa en la comercialización de envases de vidrio. Blue Sky SA - Botellas - Botellas de vidrio - Tapas rosca - Corchos - Tapones - Argentina - Mendoza - Tapas Pilfer - Screw Caps - Aluminio."
      />
      <div className="my-background">
        <SiteStructure>
          <div className="share-box">
            <a
              className="facebook-icon pb-5"
              href="https://www.facebook.com/blueskysaMza"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#FFFFFF",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <FaFacebook />
              </IconContext.Provider>
            </a>
            {/* <a
              className="whatsapp-icon pb-5"
              href="https://wa.me/+5492614158538"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#FFFFFF",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <FaWhatsapp />
              </IconContext.Provider>
            </a>{" "} */}
            <a
              className="whatsapp-icon pb-5"
              href="https://wa.me/+5492614543598"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#FFFFFF",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <FaWhatsapp />
              </IconContext.Provider>
            </a>
            <a
              className="email-icon pb-5"
              href="mailto:info@blueskysa.com.ar"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#FFFFFF",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <GrMail />
              </IconContext.Provider>
            </a>
            <a
              className="instagram-icon pb-5"
              href="https://www.instagram.com/bluesky.s.a/"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#FFFFFF",
                  size: 24,
                }}
              >
                <FaInstagram />
              </IconContext.Provider>
            </a>
          </div>
          <CarouselAR />
        </SiteStructure>
      </div>
    </>
  );
};

export default IndexPage;
