import React from "react";

import Carousel from "react-bootstrap/Carousel";

import {
  botella1,
  botella2,
  botella3,
  botella4,
  frase1,
  frase2,
  frase3,
  frase4,
} from "../../images/Carousel/";
import "./Carousel.scss";

const CarouselAR = () => (
  <Carousel>
    <Carousel.Item>
      <div className="slide-label__first-label  d-sm-block d-md-none">
        <div className="slide-label__first-label-title">
          La Calidad como simbolo de identidad
        </div>
      </div>
      <img
        src={frase1}
        className="carousel-image-phrase d-sm-none d-md-block"
        alt="First phrase"
      />
      <img
        className="carousel-image d-block"
        src={botella1}
        alt="First slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={frase2}
        className="carousel-image-phrase d-sm-none d-md-block"
        alt="Second phrase"
      />

      <img
        className="d-block carousel-image "
        src={botella2}
        alt="Second slide"
      />
      <div className="slide-label__second-label  d-sm-block d-md-none">
        <div className="slide-label__second-label-title">
          Inovacción y Vanguardia
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={frase3}
        className="carousel-image-phrase d-sm-none d-md-block"
        alt="Third phrase"
      />

      <img
        className="d-block carousel-image "
        src={botella3}
        alt="Third slide"
      />
      <div className="slide-label__third-label  d-sm-block d-md-none">
        <div className="slide-label__third-label-title">
          Flexibilidad y Productividad
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <img
        src={frase4}
        className="carousel-image-phrase d-sm-none d-md-block"
        alt="Fourth phrase"
      />

      <img
        className="d-block carousel-image "
        src={botella4}
        alt="Fourth slide"
      />
      <div className="slide-label__fourth-label  d-sm-block d-md-none">
        <div className="slide-label__fourth-label-title">
          Perfección hasta al más minimo detalle
        </div>
      </div>
    </Carousel.Item>
  </Carousel>
);

export default CarouselAR;
